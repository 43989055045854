import React from "react";
import Countdown from "react-countdown";
import AboutUsModal from "../../components/AboutUsModal";
import CountDownComponent from "../../components/CountDownComponent";
import { countDownEndTime, showCountDown } from "../../config/themeConfig";
import { landingPageData, socialMediaData } from "../../config/dataConfig";
import { mailChimpUrl } from "../../config/themeConfig";
import { Tooltip } from "../../components/Tooltip";
import { contactData } from "../../config/dataConfig";
import ContactForm3 from "../../components/ContactForm3";
import SubscribeForm from "../../components/SubscribeForm";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import CustomZohoForm from "../../components/CustomZohoForm";

const SolidColorPrimary3 = () => {
  return (
    <div id="main-wrapper">
      <div className="container-fluid px-0">
        <div className="row g-0 min-vh-100">
          {/* Intro
========================= */}
          <div className="col-lg-7 d-flex flex-column bg-primary">
            {/* Domain Details */}
            <div className="container py-5 px-4 px-lg-5 my-auto">
              <div className="row text-center">
                {showCountDown && (
                  <div className="col-auto text-white text-center mx-auto mb-4 pb-2">
                    <Countdown
                      zeroPadTime={3}
                      date={countDownEndTime}
                      renderer={CountDownComponent}
                    ></Countdown>
                  </div>
                )}
                <div className="col-12 mx-auto mb-4">
                  {/* Logo */}
                  <a className="logo" href="index.html" title="Zoon">
                    {" "}
                    <img src="images/logo.png" alt="Zoon" />{" "}
                  </a>
                  {/* Logo End */}
                </div>
                <div className="col-12 mx-auto">
                  <h1 className="text-9 text-black bg-danger d-inline-block fw-700 rounded px-3 py-2 mb-4">
                    {landingPageData.coming_soon}
                  </h1>
                  <h2 className="text-8 text-black fw-600 mb-4">
                    {landingPageData.heading}
                  </h2>
                </div>
                <div className="col-md-9 col-xl-8 mx-auto">
                  <p className="text-4 text-black mb-4">
                    نحن نشتغل بكل جهد لإطلاق منصة جديدة تجمع بين أفضل الوظائف والدورات التدريبية اللي تساعدك في تطوير مهاراتك. قريبًا، بتقدر تستكشف فرص مهنية متنوعة ودورات تعليمية تدعم مسارك الوظيفي.
                    ترقبوا الجديد، لأننا بنقدّم لكم شيء فعلاً مميز!
                  </p>
                  <p className="text-4 text-black mb-4 justify-end">
                    <strong>
                      وش تتوقعون؟
                    </strong>
                    <ul className="list-unstyled text-4 text-black mb-4">
                      <li>وظائف من أكبر الشركات في السوق</li>
                      <li>دورات تعليمية حسب الفجوات المهارية عندك</li>
                      <li>تكامل مع أفضل منصات التعليم الإلكتروني مثل Coursera</li>
                      <li>واجهة استخدام سهلة و مريحة لضمان أفضل تجربة</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
            <div className="container pb-2 px-3">
              <div className="row text-black">
                <div className="col-md-4 d-flex align-items-center justify-content-center justify-content-md-end mt-2 mt-md-0">
                  <ul className="social-icons social-icons-dark">
                    {socialMediaData.length > 0 &&
                      socialMediaData.map((value, index) => (
                        <li className={value.class} key={index}>
                          <Tooltip text={value.name} placement="top">
                            <a
                              data-bs-toggle="tooltip"
                              href={value.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              title={value.name}
                            >
                              <i className={value.icon} />
                            </a>
                          </Tooltip>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Intro End */}
          {/* Contact Us
========================= */}
          <div className="col-lg-5 d-flex flex-column bg-light text-center ">
            <div className="container my-auto py-5 pt-lg-4 pb-lg-2">
              <div className="row mx-0">
                <div className="col-11 col-lg-10 mx-auto">
                  <h3 className="mb-3 fw-600">تابعونا واستمروا معنا</h3>
                  <p className="text-black-50 mb-4">عشان توصلكم أحدث الأخبار، تأكدوا من أنو تشتركوا في نشرتنا البريدية
                    شكرًا على اهتمامكم وثقتكم فينا، وإحنا متحمسين نقدم لكم كل جديد ومفيد</p>
                  {/* Contact Form */}
                  <script type="text/javascript" src="https://campaigns.zoho.sa/js/zc.iframe.js"></script>
                  <iframe frameborder="0" id="iframewin" width="100%" height="500px" src="https://rahl-zc1.maillist-manage.sa/ua/Optin?od=1228f9083dab0&zx=122ecb6d4fe&tD=116c7ae164cd399&sD=116c7ae164cf2d9"></iframe>
                </div>
              </div>
            </div>
            {/* Footer */}
            <footer className="container">
              <p className="text-2 text-muted mb-2">
                Copyright © 2024 <a href="/">B'tawfiq</a>. All Rights Reserved.
              </p>
            </footer>
          </div>
          {/* Contact Us End */}
        </div>
      </div>
      <AboutUsModal />
    </div>
  );
};

export default SolidColorPrimary3;
